
import { setStorage } from "./use-local-storage";

const useHookReqIncrement = () => {
  let count = 0;

  function increment() {
    if(count === 0){
      setStorage("loader",true);
      window.dispatchEvent(new Event("storageChange"));
    }
    count++;
    
    return count;
  }

  function decrement() {
    if (count > 0) {
      count--;
    }

    if (count === 0) {
      setStorage("loader",false);
      window.dispatchEvent(new Event("storageChange"));
    }

    return count;
  }

  return { increment, decrement, count };
};

export default useHookReqIncrement;
