import axios from 'axios';
import { toast } from 'react-toastify';
import { compact } from 'lodash';
import useHookReqIncrement from 'src/hooks/CustomHookReqIncrement';

export const requestHeaders = { 'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone };

export const axiosInstance = axios.create({
	baseURL: process.env.NEXT_PUBLIC_HOST_API,
});

// Function to get the Cognito client ID from localStorage
const getClientId = () => {
	const clientId = Object.keys(localStorage)
		.find(
			(key) => key.startsWith('CognitoIdentityServiceProvider.') && key.endsWith('.LastAuthUser')
		)
		?.split('.')[1];

	return clientId;
};

// Function to get the username from localStorage
const getUsernameFromStorage = () => {
	const clientId = getClientId();
	if (clientId) {
		const lastAuthUserKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
		const username = localStorage.getItem(lastAuthUserKey);
		return username;
	}
	return null;
};

// Simplified token retrieval functions
const getAccessTokenFromStorage = () => localStorage.getItem('accessToken');
const getRefreshTokenFromStorage = () => localStorage.getItem('refreshToken');
const getIdTokenFromStorage = () => localStorage.getItem('idToken');

// Function to update tokens in localStorage
const updateTokensInStorage = (newTokens) => {
	const clientId = getClientId();
	const lastAuthUserKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
	const username = localStorage.getItem(lastAuthUserKey);

	const refreshTokenKey = `CognitoIdentityServiceProvider.${clientId}.${username}.refreshToken`;
	const accessTokenKey = `CognitoIdentityServiceProvider.${clientId}.${username}.accessToken`;
	const idTokenKey = `CognitoIdentityServiceProvider.${clientId}.${username}.idToken`;

	if (newTokens.RefreshToken) {
		// Update Cognito key
		localStorage.setItem(refreshTokenKey, newTokens.RefreshToken);
		// Update key
		localStorage.setItem('refreshToken', newTokens.RefreshToken);
	}
	if (newTokens.AccessToken) {
		localStorage.setItem(accessTokenKey, newTokens.AccessToken);
		localStorage.setItem('accessToken', newTokens.AccessToken);
	}
	if (newTokens.IdToken) {
		localStorage.setItem(idTokenKey, newTokens.IdToken);
		localStorage.setItem('idToken', newTokens.IdToken);
	}
};
 
const  {increment, decrement}=useHookReqIncrement()

// Function to clear authentication data from localStorage
export const clearAuthDataFromStorage = async () => {
	// Remove standard tokens and data
	localStorage.removeItem('idToken');
	localStorage.removeItem('accessToken');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('lab_cid');
	localStorage.removeItem('role');
	localStorage.removeItem('expireAt');
	localStorage.removeItem('selectedUser');

	// Remove tokens stored under CognitoIdentityServiceProvider keys
	const clientId = getClientId();
	if (clientId) {
		const lastAuthUserKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
		const username = localStorage.getItem(lastAuthUserKey);
		if (username) {
			const keysToRemove = [
				lastAuthUserKey,
				`CognitoIdentityServiceProvider.${clientId}.${username}.accessToken`,
				`CognitoIdentityServiceProvider.${clientId}.${username}.idToken`,
				`CognitoIdentityServiceProvider.${clientId}.${username}.refreshToken`,
			];
			keysToRemove.forEach((key) => localStorage.removeItem(key));
		}
	}
};

axiosInstance.interceptors.request.use(
	(config) => {
    increment()
		config.headers = requestHeaders;

		const accessToken = getAccessTokenFromStorage();
		if (accessToken) {
			config.headers['Authorization'] = `Bearer ${accessToken}`;
		}

		const idToken = getIdTokenFromStorage();
		if (idToken) {
			config.headers['idToken'] = idToken;
		}

		const labCid = localStorage.getItem('lab_cid');
		if (labCid) {
			config.headers['lab_cid'] = labCid;
		}
		const role = localStorage.getItem('role');
		if (role) {
			config.headers['role'] = role;
		}

		return config;
	},
	(error) => Promise.reject(error)
);

let refreshAndRetryQueue = [];
let isRefreshing = false;

axiosInstance.interceptors.response.use(
	(response) => {
		decrement()	
		const parts = response.config.url.split('/');
		const endpoint = parts[0] || parts[1];
		const resourceType = endpoint?.endsWith('s') ? `${endpoint?.slice(0, -1)}(s)` : endpoint || '';
		switch (response.config.method) {
			case 'put':
				toast.success(`Successfully updated ${resourceType}`);
				break;
			case 'patch':
				toast.success(`Successfully updated ${resourceType}`);
				break;
			case 'delete':
				toast.success(`Successfully deleted ${resourceType}`);
				break;
			case 'post':
				toast.success(`Successfully created ${resourceType}`);
				break;
			default:
		}
		return response;
	},
	async (error) => {
		decrement()
		const originalRequest = error.config;
		if (
			((error.response && error.response.status === 401) ||
				(!error.response && error.request && error.request.status === 401)) &&
			!originalRequest._retry
		) {
			if (!isRefreshing) {
			  isRefreshing = true;
				originalRequest._retry = true;

				try {
					// Get the stored refresh token and username
					const refreshToken = getRefreshTokenFromStorage();
					const username = getUsernameFromStorage();

					if (!refreshToken || !username) {
						// No refresh token or username available - clear storage and redirect
						await clearAuthDataFromStorage();
						window.location.replace('/');
						return Promise.reject(error);
					}

					// Call backend endpoint to refresh tokens using default axios
					await axios.post(
						`${process.env.NEXT_PUBLIC_HOST_API}/auth/refresh-tokens`,
						{
							username: username,
							refresh_token: refreshToken,
						}
					).then(res => {
						const newTokens = res.data.data;

						// Update tokens in localStorage
						updateTokensInStorage(newTokens);
						error.config.headers['Authorization'] = `Bearer ${newTokens?.accessToken}`;
						error.config.headers['idToken'] = newTokens?.idToken;
						// Retry all requests in the queue with the new token
						refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
						  axiosInstance
							.request(config)
							.then((response) => resolve(response))
							.catch((err) => reject(err));
						});
						// Clear the queue
						refreshAndRetryQueue.length = 0;
					}).catch(err => {
						console.log(err)
						clearAuthDataFromStorage();
						window.location.replace('/');
						return Promise.reject(err);
					});

					// Retry the original request with the new access token
					return axiosInstance(originalRequest);
				} catch (refreshError) {
					console.error('Refresh token attempt failed:', refreshError);

					if (refreshError.response) {
						const { status } = refreshError.response;

						if (status === 402 || status === 403) {
							// Invalid refresh token or user not found - clear storage and log out the user
							toast.error('Session expired. Please log in again.');
							await clearAuthDataFromStorage();
							window.location.replace('/');
						} else {
							// Handle other errors without logging out
							const errorMessage =
								refreshError.response.data?.message || 'An error occurred. Please try again.';
							toast.error(errorMessage);
						}
					} else {
						// No response from server or other error
						toast.error('An error occurred. Please log in again.');
						await clearAuthDataFromStorage();
						window.location.replace('/');
					}

					return Promise.reject(refreshError);
				} finally {
					isRefreshing = false;
				}
			}
			// Add the original request to the queue
			return new Promise((resolve, reject) => {
				refreshAndRetryQueue.push({ config: originalRequest, resolve, reject })
			});
		} else {
			// Handle other errors
			const status = error.response?.status ?? error.request?.status;
			const message =
				error.response?.statusText ?? error.request?.statusText ?? error.message;
			const out = compact([status, message]).join(' ');
			toast.error(`Error: ${out}`);
		}
		return Promise.reject(error);
	}
);
